/// <reference path="src/types/clarity.ts" />
/// <reference path="src/types/docsbot.ts" />

import { RouteUpdateArgs } from "gatsby";
import "./src/css/style.css";

if (
  typeof window !== "undefined" &&
  !document.getElementById("docsbotai-root")
) {
  (window.DocsBotAI = window.DocsBotAI || {}),
    (window.DocsBotAI.init = function (e) {
      return new Promise<void>((t, r) => {
        var n = document.createElement("script");
        n.type = "text/javascript";
        n.async = true;
        n.src = "https://widget.docsbot.ai/chat.js";

        let o = document.getElementsByTagName("script")[0];
        o.parentNode?.insertBefore(n, o);

        n.addEventListener("load", () => {
          let n;
          Promise.all([
            window.DocsBotAI.mount(Object.assign({}, e)),
            (n = function e(t) {
              return new Promise((e) => {
                if (document.querySelector(t))
                  return e(document.querySelector(t));
                let r = new MutationObserver((n) => {
                  if (document.querySelector(t))
                    return e(document.querySelector(t)), r.disconnect();
                });
                r.observe(document.body, { childList: !0, subtree: !0 });
              });
            })("#docsbotai-root"),
          ])
            .then(() => t())
            .catch(r);
        });
        n.addEventListener("error", (e) => {
          r(e.message);
        });
      });
    });

  window.DocsBotAI.init({ id: "RaYY3exukHqCtMyX5nuk/ApchpqLa3bwYCvJIg4Y7" });
}

if (typeof window !== "undefined" && !document.getElementById("ls-script")) {
  // LemonSqueezy Affiliate Config
  (window as any).lemonSqueezyAffiliateConfig = { store: "ableset" };

  const lsScript = document.createElement("script");
  lsScript.defer = true;
  lsScript.id = "ls-script";
  lsScript.src = "https://lmsqueezy.com/affiliate.js";
  document.head.append(lsScript);
}

if (
  typeof window !== "undefined" &&
  !document.getElementById("plausible-script")
) {
  const script2 = document.createElement("script");
  script2.async = true;
  script2.defer = true;
  script2.id = "plausible-script";
  script2.setAttribute("data-domain", "ableset.app");
  script2.src = "https://plausible.io/js/plausible.js";
  document.head.append(script2);

  (window as any).plausible = function () {
    ((window as any).plausible.q =
      ((window as any).plausible as any).q || []).push(arguments);
  };
}

if (typeof window !== "undefined") {
  window.clarity =
    window.clarity ||
    function () {
      (window.clarity!.q = window.clarity!.q || []).push(arguments);
    };
  const t = document.createElement("script");
  t.async = true;
  t.src = "https://www.clarity.ms/tag/hntv7rhdee";
  const y = document.getElementsByTagName("script")[0];
  y.parentNode?.insertBefore(t, y);
}

export const onPreRouteUpdate = () => {
  document.documentElement.classList.add("navigating");
};

export const onRouteUpdate = (args: RouteUpdateArgs) => {
  if (args.location.hash) {
    queueMicrotask(() => {
      document.documentElement.classList.remove("navigating");
      const id = args.location.hash.substring(1);
      const el = document.getElementById(id);
      el?.scrollIntoView({ block: "start" });
    });
  }
  setTimeout(() => {
    document.documentElement.classList.remove("navigating");
  }, 50);
};

export { wrapRootElement } from "./src/util/wrapper";
